<template>
	<edit-template class="role-edit" ref="edit" @confirm="save('form')" @cancel="open" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <!-- 大表单 -->
    <div class="form-body">
      <el-form ref="form" :model="form" :rules="rules" label-width="150rem">
        <el-form-item label="选择校区" prop="school_id">
          <el-select :popper-append-to-body="false" v-model="form.school_id" placeholder="请选择校区" @change="campusChange">
            <el-option v-for="(item, index) in campusArr" :key="item.id" :label="item.school_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="任务周期" prop="task_cycle_id">
          <el-select :popper-append-to-body="false" v-model="form.task_cycle_id" placeholder="请选择任务周期" @change="taskCycle">
            <el-option v-for="item in taskcycleArr" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <div class="container">
          <el-row v-for="(item, index) in form.coach" style="margin-top: 20rem" class="">
            <el-col :span="20" class="container-align bg_rd">
              <el-form-item label="选择学科">
                <el-select :popper-append-to-body="false" v-model="form.coach[index].subject_id" placeholder="请选择学科" @change="subjectChange(index)">
                  <el-option v-for="(item, index) in disciplineArr" :key="item.id" :label="item.subject_name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="最低辅导次数">
                <el-input-number v-model="form.coach[index].number" :min="minNumber" label="最低辅导次数"></el-input-number>
              </el-form-item>

              <el-form-item label="选择教师" style="display: block;">
                <el-button @click.prevent="dialogShow(item, index)" type="primary">选择教师</el-button>
                <div v-if="form.coach[index].teacher_ids.length">
                  <el-tag v-for="item in form.coach[index].teacherObj" style="margin-right: 10rem">{{ JSON.parse(item).clerk_name }}</el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="2" style="color: #fff"></el-col>
            <el-col :span="3" style="padding-top: 20rem; display: flex; margin: auto; padding-left: 50rem">
              <el-button @click.prevent="deleteform(index)" v-if="form.coach.length !== 1">删除</el-button>
              <el-button @click.prevent="addform" type="primary" v-if="form.coach.length == index + 1">添加</el-button>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <!-- 底部按钮 -->
    

    <el-dialog title="选择老师" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-checkbox v-model="checked" border="" @change="allTeachers" v-if="teachersArr.length">全部教师</el-checkbox>
      <br>
      <br>
      <el-checkbox-group v-model="teacher_ids">
        <el-checkbox v-for="(item, index) in teachersArr" :label="JSON.stringify(item)" border="" @change="teachersChange">{{
            item.clerk_name
          }}
        </el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="teachersSave">保存</el-button>
			</span>
    </el-dialog>
  
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    return {
      form: {
        task_cycle_id: '',
        coach: [
          {
            subject_id: '',
            number: 1,
            teacher: [],
            teacherObj: [],
            teacher_ids: []
          }
        ]
      },
      minNumber: 1,
      index: null,
      teacher_ids: [],
      campusArr: [],
      taskcycleArr: [],
      disciplineArr: [],
      teachersArr: [],
      rules: {
        school_id: [{required: true, message: '请选择校区', trigger: 'change'}],
        task_cycle_id: [{required: true, message: '请选择任务周期', trigger: 'change'}]
      },
      dialogVisible: false,
      checked: false
    }
  },

  methods: {
    save(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          for (let index = 0; index < this.form.coach.length; index++) {
            if (this.form.coach[index].subject_id == '') {
              return this.$message.error('请选择科目')
            }
            if (this.form.coach[index].teacher_ids.length == 0) {
              return this.$message.error('请选择教师')
            }
          }
          this.$_axios2.post('api/1v1/coach', this.form).then(res => {
            if (res.data.status === 0) {
              this.$message.success('新增成功')
              this.$store.commit('setPage', 1)
              this.$router.back()
            }
          })
        }
      })
    },
    open() {
      this.$confirm('是否取消新增', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.back()
      })
    },
    deleteform(index) {
      if (this.form.coach.length == 1) {
        this.$message.error('1111111111')
      } else {
        this.form.coach.splice(index, 1)
      }
    },
    addform() {
      this.form.coach.push({
        subject_id: '',
        number: 1,
        teacher: [],
        teacherObj: [],
        teacher_ids: []
      })
    },
    async dialogShow(val, index) {
      this.index = index
      this.dialogVisible = true
      await this.$_axios2
          .get('api/1v1/coach/get-teacher', {
            params: {
              school_id: this.form.school_id,
              subject_id: val.subject_id
            }
          })
          .then(res => {
            this.teachersArr = res.data.data
            this.teacher_ids = this.form.coach[index].teacher
            if (this.form.coach[index].teacher.length == this.teachersArr.length) {
              this.checked = true
            } else this.checked = false
            if (this.form.coach[index].teacher.length == 0) {
              this.checked = false
            }
          })
    },
    allTeachers(val) {
      if (val) {
        this.teacher_ids = this.teachersArr.map(item => JSON.stringify(item))
      } else this.teacher_ids = []
    },
    teachersChange() {
      if (this.teacher_ids.length == this.teachersArr.length) {
        this.checked = true
      } else this.checked = false
    },
    teachersSave() {
      this.dialogVisible = false
      this.form.coach[this.index].teacherObj = this.teacher_ids
      this.form.coach[this.index].teacher = this.teacher_ids.map(item => item)
      this.form.coach[this.index].teacher_ids = []
      this.teacher_ids.forEach(item => {
        this.form.coach[this.index].teacher_ids.push(JSON.parse(item).id)
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    campusChange(val) {
      this.form.task_cycle_id = ''
      this.$_axios2.get('api/1v1/coach/get-task-cycle?school_id=' + val).then(res => {
        this.taskcycleArr = res.data.data
      })
      this.form.coach.forEach(item => {
        item.number = 1
        item.teacher = []
        item.teacherObj = []
        item.teacher_ids = []
      })
    },
    taskCycle(val) {
      this.taskcycleArr.forEach(item => {
        if (item.id == val) {
          this.minNumber = item.number
          this.form.coach.forEach(item => {
            item.number = this.minNumber
          })
        }
      })
    },
    subjectChange(index) {
      this.form.coach[index].teacherObj = []
      this.form.coach[index].teacher = []
    }
  },

  created() {
    this.$_axios.get('site/school').then(res => {
      this.campusArr = res.data.data
    })
    this.$_axios.get('site/fist-subject').then(res => {
      this.disciplineArr = res.data.data
    })
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20rem;
  padding: 20rem;
}

.container-align {
  padding-top: 20rem;
  display: flex;
  flex-wrap: wrap;

  .form_item {
    width: 12vw !important;
  }
}

@media (max-width: 1322rem) {
  .container {
    width: 1000rem !important;
  }
}

.el-checkbox-group {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: left !important;
  margin-left: 0rem !important;
}

.el-checkbox {
  margin-left: 0rem !important;
}

::v-deep .el-checkbox {
  min-width: 50rem !important;
  margin-bottom: 10rem !important;
}
</style>
